import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";

const ZeusdbStatue = () => {
  // documentation

  // loose svms

  // install osirius ************************

  const [pythonIO, setpythonIO] = useState(true);
  const [rubyIO, setrubyIO] = useState(false);
  const [javascriptIO, setjavascriptIO] = useState(false);

  const firepythonIO = () => {
    //console.log(pythonCP);
    if (pythonIO === false) {
      setpythonIO(true);
      setjavascriptIO(false);
      setrubyIO(false);
    }
  };

  const firerubyIO = () => {
    if (rubyIO === false) {
      setrubyIO(true);
      setpythonIO(false);
      setjavascriptIO(false);
    }
  };

  const firejavascriptIO = () => {
    if (javascriptIO === false) {
      setjavascriptIO(true);
      setpythonIO(false);
      setrubyIO(false);
    }
  };

  // install osirius ************************

  // create platform ***********************************
  const [pythonCP, setpythonCP] = useState(true);
  const [rubyCP, setrubyCP] = useState(false);
  const [javascriptCP, setjavascriptCP] = useState();

  const firepythonCP = () => {
    if (pythonCP === false) {
      setrubyCP(false);
      setpythonCP(true);

      // setjavascriptGS(false);
    }
  };

  const firerubyCP = () => {
    if (rubyCP === false) {
      setpythonCP(false);
      setrubyCP(true);

      // setjavascriptGS(false);
    }
  };

  const firejavascriptCP = () => {
    if (javascriptCP === true) {
      setjavascriptCP(false);
    } else if (javascriptCP === false) {
      setjavascriptCP(true);
    }
  };

  // create platform ****************************

  // get structure *******************************

  const [pythonGS, setpythonGS] = useState(true);
  const [rubyGS, setrubyGS] = useState(false);
  const [javascriptGS, setjavascriptGS] = useState(false);

  const firepythonGS = () => {
    if (pythonGS === false) {
      setpythonGS(true);
      setrubyGS(false);
      setjavascriptGS(false);
    }
  };

  const firerubyGS = () => {
    if (rubyGS === false) {
      setrubyGS(true);
      setpythonGS(false);

      setjavascriptGS(false);
    }
  };

  const firejavascriptGS = () => {
    if (javascriptGS === false) {
      setjavascriptGS(true);
      setrubyGS(false);
      setpythonGS(false);
    }
  };

  // get structure *****************************

  // get all structures ***********************

  const [pythonGAS, setpythonGAS] = useState(true);
  const [rubyGAS, setrubyGAS] = useState(false);
  const [javascriptGAS, setjavascriptGAS] = useState();

  const firepythonGAS = () => {
    if (rubyGAS === true) {
      setrubyGAS(false);
    }

    if (javascriptGAS === true) {
      setjavascriptGAS(false);
    }
    if (pythonGAS === true) {
      setpythonGAS(false);
    } else if (pythonGAS === false) {
      setpythonGAS(true);
    }
  };

  const firerubyGAS = () => {
    // if (pythonGAS === true) {
    //   setpythonGAS(false);
    // }
    // if (javascriptGAS === true) {
    //   setjavascriptGAS(false);
    // }
    if (rubyGAS === true) {
      setrubyGAS(false);
    } else if (rubyGAS === false) {
      setrubyGAS(true);
    }
  };

  const firejavascriptGAS = () => {
    if (javascriptGAS === true) {
      setjavascriptGAS(false);
    } else if (javascriptGAS === false) {
      setjavascriptGAS(true);
    }
  };

  // get all structures **********************

  // create a structure *************************

  const [pythonCAS, setpythonCAS] = useState(true);
  const [rubyCAS, setrubyCAS] = useState(false);
  const [javascriptCAS, setjavascriptCAS] = useState();

  const firejavascriptCAS = () => {
    if (javascriptCAS === true) {
      setjavascriptCAS(false);
    } else if (javascriptCAS === false) {
      setjavascriptCAS(true);
    }
  };

  const firepythonCAS = () => {
    if (pythonCAS === true) {
      setpythonCAS(false);
    } else if (pythonCAS === false) {
      setpythonCAS(true);
    }
  };

  const firerubyCAS = () => {
    if (rubyCAS === true) {
      setrubyCAS(false);
    } else if (rubyCAS === false) {
      setrubyCAS(true);
    }
  };
  // create a structure *************************

  // update a structure *************************

  const [pythonUS, setpythonUS] = useState(true);
  const [rubyUS, setrubyUS] = useState(false);
  const [javascriptUS, setjavascriptUS] = useState();

  const firepythonUS = () => {
    if (pythonUS === false) {
      setpythonUS(true);
      setrubyUS(false);
      setjavascriptUS(false);
    }
  };

  const firerubyUS = () => {
    if (rubyUS === false) {
      setrubyUS(true);
      setpythonUS(false);

      setjavascriptUS(false);
    }
  };

  const firejavascriptUS = () => {
    if (javascriptUS === false) {
      setjavascriptUS(true);
      setrubyUS(false);
      setpythonUS(false);
    }
  };
  // update a structure *************************

  // delete a structure ****************************

  const [pythonDS, setpythonDS] = useState(true);
  const [rubyDS, setrubyDS] = useState(false);
  const [javascriptDS, setjavascriptDS] = useState();

  const firejavascriptDS = () => {
    if (javascriptDS === true) {
      setjavascriptDS(false);
    } else if (javascriptUS === false) {
      setjavascriptDS(true);
    }
  };

  const firepythonDS = () => {
    if (pythonDS === true) {
      setpythonDS(false);
    } else if (pythonUS === false) {
      setpythonDS(true);
    }
  };

  const firerubyDS = () => {
    if (rubyUS === true) {
      setrubyUS(false);
    } else if (rubyUS === false) {
      setrubyUS(true);
    }
  };
  // delete a structure ********************

  // useEffect(() => {
  //   firepythonGAS();
  // }, [firepythonGAS]);

  return (
    <div>
      <div className="statue-zeusdb-header">
        <Link className="statue-lantern" to="/login">
          Zeus{" "}
        </Link>{" "}
        Osirius
      </div>
      <div className="statue-lattice">
        <div className="statue-zeusdb-trim">
          <div className="hind">
            <div>
              Documents
              <div>
                <div>get</div>
                {/* <div>post</div>
            <div>edit ( put push )</div>
            <div>delete</div>
            drystate */}
              </div>
            </div>
          </div>
        </div>

        <div className="statue-zeusdb-centerIsle">
          <div className="statue-zeusdb-platefunctions">download</div>
          <div className="statue-zeusdb-plates">
            <div className="statue-zeusdb-plates-engravings">
              <div
                onClick={firepythonIO}
                className="statue-zeusdb-plates-engraving"
              >
                {" "}
                Python
              </div>
              <div
                onClick={firerubyIO}
                className="statue-zeusdb-plates-engraving"
              >
                Ruby{" "}
              </div>

              {/* <div
                onClick={firejavascriptIO}
                className="statue-zeusdb-plates-engraving"
              >
                Javascript
              </div> */}
              <div className="statue-zeusdb-plates-zeusyoomlion">Z</div>
            </div>
            <div className="statue-zeusdb-plates-code">
              {pythonIO && <div>$ python3 install osirius</div>}
              {rubyIO && <div>$ gem install osirius </div>}
              {javascriptIO && <div>$ npm install osirius </div>}
            </div>
          </div>

          {/* <div>start server</div>
        <div> server </div>
        <div>simple requests</div> */}

          <div className="statue-zeusdb-leaderplateplatforms">
            <div className="statue-zeusdb-platefunctions"> 🌠 Platforms 🌠</div>
            <div>
              <div className="statue-zeusdb-platetitles">create platform</div>

              <div className="statue-zeusdb-plates">
                <div>
                  {/* <div>go </div> */}
                  <div className="statue-zeusdb-plates-engravings">
                    <div
                      className="statue-zeusdb-plates-engraving"
                      onClick={firepythonCP}
                    >
                      {" "}
                      Python{" "}
                    </div>
                    <div
                      className="statue-zeusdb-plates-engraving"
                      onClick={firerubyCP}
                    >
                      Ruby{" "}
                    </div>

                    {/* <div
                    className="statue-zeusdb-plates-engraving"
                    onClick={firejavascriptCP}
                  >
                    Javascript
                  </div> */}
                    <div className="statue-zeusdb-plates-zeusyoomlion">Z</div>
                  </div>
                </div>
                <div className="statue-zeusdb-plates-code">
                  {pythonCP && (
                    <div>
                      <div>
                        <code> 1 </code>
                      </div>

                      <div>
                        <code> 2 </code> # initialize platform
                      </div>

                      <div>
                        <code> 3 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 4 </code> oy =
                        Osirius.Bolt("Dios-Favorite-Snacks")
                      </div>
                      <div>
                        {" "}
                        <code> 5 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 6 </code>
                      </div>
                    </div>
                  )}
                  {rubyCP && (
                    <div>
                      <div>
                        <code> 1 </code>
                      </div>

                      <div>
                        <code> 2 </code> # initialize platform
                      </div>

                      <div>
                        <code> 3 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 4 </code> oy =
                        Osirius::Bolt.new("Dios-Favorite-Snacks")
                      </div>
                      <div>
                        {" "}
                        <code> 5 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 6 </code>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="statue-zeusdb-platefunctions">get</div>
            <div>
              <div className="statue-zeusdb-platetitles">
                get structure by id
              </div>

              <div className="statue-zeusdb-plates">
                <div>
                  {/* <div>go </div> */}

                  <div className="statue-zeusdb-plates-engravings">
                    <div
                      className="statue-zeusdb-plates-engraving"
                      onClick={firepythonGS}
                    >
                      {" "}
                      Python{" "}
                    </div>{" "}
                    <div
                      className="statue-zeusdb-plates-engraving"
                      onClick={firerubyGS}
                    >
                      Ruby{" "}
                    </div>{" "}
                    {/* <div
                    className="statue-zeusdb-plates-engraving"
                    onClick={firejavascriptGS}
                  >
                    Javascript
                  </div> */}
                    <div className="statue-zeusdb-plates-zeusyoomlion">Z</div>
                  </div>
                </div>
                <div className="statue-zeusdb-plates-code">
                  {pythonGS && (
                    <div>
                      <div>
                        {" "}
                        <code> 1 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 2 </code> # find structure
                      </div>
                      <div>
                        {" "}
                        <code> 3 </code>
                      </div>
                      <div>
                        {" "}
                        <code>
                          {" "}
                          4{" "}
                        </code> oy.findByID("5euz37c2-i3g8-3471-t2"){" "}
                      </div>
                      <div>
                        {" "}
                        <code> 5 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 6 </code>
                      </div>
                    </div>
                  )}
                  {rubyGS && (
                    <div>
                      <div>
                        {" "}
                        <code> 1 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 2 </code> # find structure
                      </div>
                      <div>
                        {" "}
                        <code> 3 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 4 </code> oy.findByID("5euz37c2-i3g8-3471-t2")
                      </div>
                      <div>
                        {" "}
                        <code> 5 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 6 </code>
                      </div>
                    </div>
                  )}
                  {javascriptGS && (
                    <div>
                      <div>
                        {" "}
                        <code> 1 </code> python * check *{" "}
                      </div>
                      <div>
                        {" "}
                        <code> 2 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 3 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 4 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 5 </code>
                      </div>
                      <div>
                        {" "}
                        <code> 6 </code>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* 
          <div>
            <div className="statue-zeusdb-platetitles">get all structures</div>
            <div className="statue-zeusdb-plates">
              <div className="statue-zeusdb-plates-engravings">
                <div
                  className="statue-zeusdb-plates-engraving"
                  onClick={firepythonGAS}
                >
                  Python
                </div>
                <div
                  className="statue-zeusdb-plates-engraving"
                  onClick={firerubyGAS}
                >
                  Ruby
                </div>
                <div
                  className="statue-zeusdb-plates-engraving"
                  onClick={firejavascriptGAS}
                >
                  Javascript
                </div>
                <div className="statue-zeusdb-plates-zeusyoomlion">Z</div>
              </div>

              <div className="statue-zeusdb-plates-code">
                {rubyGAS && (
                  <div>
                    <div>
                      <code>1</code>
                      require osirius dionysus = osirius.findall
                    </div>
                    <div>
                      <code> 2 </code>
                    </div>
                    <div>
                      <code> 3 </code>
                    </div>
                    <div>
                      <code> 4 </code>
                    </div>
                    <div>
                      <code> 5 </code>
                    </div>
                  </div>
                )}
                {pythonGAS && (
                  <div>
                    <div>
                      <code> 1 </code>
                      import osirius
                    </div>
                    <div>
                      <code> 2 </code>
                      dionysus = osirius.findAll
                    </div>
                    <div>
                      <code> 3 </code>
                    </div>
                    <div>
                      <code> 4 </code>
                    </div>
                    <div>
                      <code> 5 </code>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div> */}
          </div>
          {/* <div>
          <div className="statue-zeusdb-platefunctions">post</div>
          <div>
            <div>
              <div className="statue-zeusdb-platetitles"> create Structure</div>

              <div className="statue-zeusdb-plates">
                <div className="statue-zeusdb-plates-engravings">
                  <div className="statue-zeusdb-plates-engraving"> Python</div>
                  <div className="statue-zeusdb-plates-engraving">Ruby</div>
                  <div className="statue-zeusdb-plates-engraving">
                    Javascript
                  </div>
                  <div className="statue-zeusdb-plates-zeusyoomlion">Z</div>
                </div>
                <div className="statue-zeusdb-plates-code">
                  <div>go * check * </div>
                  <div>ruby * check * </div>
                  <div>python * check * </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
          {/* <div>
          <div className="statue-zeusdb-platefunctions">update</div>
          <div>
            <div className="statue-zeusdb-platetitles">
              update structure by id
            </div>
            <div className="statue-zeusdb-plates">
              <div className="statue-zeusdb-plates-engravings">
               
                <div className="statue-zeusdb-plates-engraving">Python</div>
                <div className="statue-zeusdb-plates-engraving">Ruby</div>

                <div className="statue-zeusdb-plates-engraving">Javascript</div>
                <div className="statue-zeusdb-plates-zeusyoomlion">Z</div>
              </div>
              <div className="statue-zeusdb-plates-code">
                <div>python * check *</div> <div>ruby * finish *</div>{" "}
                <div>js *</div>{" "}
              </div>
            </div>
          </div>
        </div> */}
          {/* 
        <div>
          <div className="statue-zeusdb-platefunctions">delete</div>
          <div>
            <div className="statue-zeusdb-platetitles">
              delete structure by id
            </div>
            <div className="statue-zeusdb-plates">
              <div>
               
                <div c className="statue-zeusdb-plates-engraving">
                  Python
                </div>{" "}
                <div className="statue-zeusdb-plates-engraving"> Ruby</div>{" "}
                <div className="statue-zeusdb-plates-engraving">Javascript</div>
              </div>
              <div className="statue-zeusdb-plates-code">
                <div>python * finish *</div> <div>ruby * finish *</div>{" "}
                <div>javascript * check*</div>
              </div>
            </div>
          </div>
        </div> */}

          {/* <div>developer tricks ( sessions or transactions ) </div> */}
        </div>
      </div>
    </div>
  );
};

export default ZeusdbStatue;
