import React from "react";

const TempleGridChapelCourtYard = () => {
  return (
    <div className="templegrid">
      <div className="templegrid-courtyard-setting"></div>
      <div className="templegrid-courtyard">
        <div className="templegrid-courtyard-plate">
          <div>
            <div className="templegrid-courtyard-plate-title">cash back</div>
            <div className="templegrid-courtyard-plate-price">$32</div>
          </div>
          <div className="templegrid-courtyard-plate-ages">
            <div>29 2024</div> <div> 28 2024 </div> <div> 27 2024 </div>{" "}
            <div>26 2024</div> <div> 25 2024</div>
          </div>
          <div className="templegrid-courtyard-plate-investment">
            100$ every month , 100$ every two months ?
          </div>
          <div className="templegrid-courtyard-plate-proposals">
            29 {">"} $1200 {">"} 35 {">"} $8400
          </div>
          <div>
            29 {">"} $600 {">"} 35 {">"} $4200
          </div>
          <div className="templegrid-courtyard-plate-deposits">
            deposits ? ... cash back options
          </div>
          <div className="templegrid-courtyard-plate-investment">
            29 {">"} $1200 {">"} 35 {">"} $8400 + $4500 = $12,900
          </div>
          <div className="templegrid-courtyard-plate-investment">
            29 {">"} $600 {">"} 35 {">"} $4200 + $4500 = $8,700
          </div>
        </div>
        <div className="templegrid-courtyard-statue">
          <div> std lab work 100$-500$ </div>
          <div> lifting operations , hernia $5000 </div>
          <div> broken arm $2500</div>
          <div> x-ray $100-$1000</div>
          {/* <div> 25$</div> */}
        </div>
        <div className="templegrid-disclaimer">
          disclaimer : this is not an advertisement for HSAs
        </div>
      </div>
    </div>
  );
};

export default TempleGridChapelCourtYard;
