import React from "react";

import LandingPage from "./courtyard/entrance/LandingPage.js";

import LandPageCover from "./courtyard/entrance/LandPageCover.js";

import {
  BrowserRouter as Router,
  // Redirect,
  Route,
  Routes,
} from "react-router-dom";
import Altar from "./temple/Altar/Altar.js";

import ZeusdbStatue from "./statues/zeusdb/ZeusdbStatue.js";
import Door from "./courtyard/door/Door.js";
import Porch from "./courtyard/door/Porch.js";
import TempleGridChapelCourtYard from "./courtyard/templegrid/entrance/TempleGridChapelCourtYard.js";

const App = () => {
  let theRoutes = (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      {/* <Route path="/house-login" element={<HouseEntrance />} /> */}
      <Route path="/house123" element={<Altar />} />
      <Route
        path="/documentation-august17-saintfeastdays-with-sept11-istherealso"
        element={<ZeusdbStatue />}
      />
      <Route path="/TempleGrid" element={<TempleGridChapelCourtYard />} />

      <Route path="/login" element={<Porch />} />
    </Routes>
  );
  return (
    <Router>
      <div>{theRoutes}</div>
    </Router>
  );
};

export default App;
