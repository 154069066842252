import React, { useEffect } from "react";

import axios from "axios";

const Altarconsole = () => {
  ////// console nav bar

  // nav bar ( just , current cluster)

  // GET request for platforms

  // axios({
  //   method: "get",
  //   proxy: {
  //     protocol: "http",
  //     host: "127.0.0.1",
  //     port: 9000,
  //   },
  // }).then(function (response) {
  //   console.log(response.data);
  //   console.log(response.status);
  //   console.log(response.statusText);
  //   console.log(response.headers);
  //   console.log(response.config);
  // });

  // GET request for STRUCTURES

  // variables cloud id from login , base id

  useEffect(() => {
    const cloudIdTest = "cloud-id-13ee2";

    const baseIdTest = "gavs-new-data-base-29";

    axios({
      method: "get",
      baseURL: "http://10.0.0.16:5001/osirius",
      url: `getPlatform/${cloudIdTest}/${baseIdTest}`,
      // proxy: {
      //   protocol: "http",
      //   host: "localhost",
      //   port: 5001,
      // },
    }).then(function (response) {
      console.log(response.data);
      console.log(response.status);
      console.log(response.statusText);
      console.log(response.headers);
      console.log(response.config);
    });
  }, []);

  // DELETE request for structures

  // axios({
  //   method: "delete",
  //   proxy: {
  //     protocol: "http",
  //     host: "127.0.0.1",
  //     port: 9000,
  //   },
  // });

  //

  return (
    <div className="altar-console">
      <div className="altar-console-cloth">
        console nav bar
        <div>
          <div> zeus db data </div>
          <div>
            <div>platforms </div>

            <div>data sheets springing off platforms ( structures )</div>
          </div>

          {/* <div> light data </div>
          <div> temple grid integration </div> */}
        </div>
      </div>
    </div>
  );
};

export default Altarconsole;
