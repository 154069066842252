import React from "react";

const Altarnavbar = () => {
  ////////// console select (select clusters)

  // get call to clusters from zeus db

  return (
    <div className="altar-molding">
      <div>
        <div>console select ( select clusters )</div>

        <div>
          documentation
          <div>zeus db</div>
          <div>light</div>
          <div>temple grid</div>
        </div>
        <hr />
        <div>current cluster</div>
        <div>get connection string</div>
        <div>cluster settings</div>
      </div>
    </div>
  );
};

export default Altarnavbar;
