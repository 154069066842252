import React from "react";
import Altarconsole from "./Altarconsole";
import Altarnavbar from "./Altarnavbar";
import Altarhallway from "./Altarhallway";

const Altar = () => {
  // state for console ( altarconsole ) , and clusters ( altarhallway )

  return (
    <div className="altar-area">
      Altar
      <div></div>
      <Altarnavbar />
      <Altarconsole />
      <Altarhallway />
    </div>
  );
};

export default Altar;
