import React from "react";

const Altarhallway = () => {
  // curtain

  // has state

  // GET request with clusters

  return <div> hallway from navbar to access clusters</div>;
};

export default Altarhallway;
