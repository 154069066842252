import React from "react";
import { Link } from "react-router-dom";

const Porch = () => {
  return (
    <div className="porch">
      <div className="path">zeus</div>
      <div className="door-frame">
        <div className="door-molding">
          <div className="door-molding-crown">username</div>
          <div>
            <input className="door-molding-etching" />
          </div>
          <div className="door-molding-crown">password</div>
          <div>
            <input className="door-molding-etching" />
          </div>
          <div className="door-handle-enter">
            <div className="door-handle-enter-text"> login </div>
          </div>
          <div className="door-handle-navigate-login">
            <Link to="/login">sign up</Link>
          </div>
          <div className="door-handle-navigate-forgotpw">
            <Link to="/login">forgot password</Link>
          </div>
        </div>
      </div>
      <div className="greeterblock">
        <div className="greeterleft">Zeusdb&copy;</div>
        <div className="greeterright">z</div>
      </div>
    </div>
  );
};

export default Porch;
