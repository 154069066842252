import React from "react";

import "../../css/style.css";
import { Link } from "react-router-dom";

const LandingPage = () => {
  // page overlay

  // header , nav buttons , login , sign up

  // zeus db title , info

  return (
    <div className="cover">
      <div>
        <div className="container">
          <div className="homepage_nav">
            <div className="homepage_nav_resources-left">
              <div className="inlines homepage_buttons homepage_nav-title zeustitle">
                zeus
              </div>
              <div className="mobile_display">
                <Link className="inlines homepage_buttons" to="/">
                  database
                </Link>
                <Link className="inlines homepage_buttons" to="/">
                  documentation
                </Link>

                {/* <div className="inlines homepage_buttons">developers</div>
                <div className="inlines homepage_buttons">resources</div> */}
              </div>
            </div>
            <div className="inlines_cover align-right homepage_nav_resources-left">
              <div className="mobile_display">
                <div className="inlines homepage_buttons-right homepage_nav_resources-left-title1">
                  beta
                </div>
                <div className="inlines homepage_buttons-right homepage_nav_resources-left-title2">
                  sign in
                </div>
              </div>
            </div>
          </div>

          {/* <div className="homepage_display">hello</div> */}
        </div>
        {/* <div className="homepage_display">hello</div> */}
      </div>
      <div>
        <div className="container-labels">
          <div className="homepage_info">
            <div className="homepage_info-titles"> Zeusdb. </div>
            <div className="homepage_info-titles"> Light. </div>
            <div className="homepage_info-titles"> TempleGrid. </div>
          </div>
        </div>
        <div className="homepage_display">
          <div className="homepage_display-zeusdb">
            <div className="homepage_display-zeusdb-titleblock">
              <div>MyDataBase</div>
            </div>
            <div className="homepage_display-zeusdb_graphblock">
              <div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
              </div>
              <div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
              </div>
              <div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
              </div>
              <div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
                <div className="homepage_display-zeusdb_graphblock-graph"></div>
              </div>
              <div className="homepage_display-zeusdb_graphblock-title">
                Data
              </div>
            </div>
            <div className="homepage_display-zeusdb_navblock">
              <div className="homepage_display-zeusdb_navblock-title1">
                platform1
              </div>
              <div className="homepage_display-zeusdb_navblock-title12">
                platform2
              </div>
              <div className="homepage_display-zeusdb_navblock-title12">
                platform3
              </div>
            </div>
            <div className="homepage_display-zeusdb_displayblock">
              <div className="homepage_display-zeusdb_displayblock-displays">
                <div>name:"Simon Peter </div>
                <div>id:172639</div>
              </div>
              <div className="homepage_display-zeusdb_displayblock-displays">
                <div>name:"Cornelius Gaius" </div>
                <div>id:985731</div>
              </div>
              <div className="homepage_display-zeusdb_displayblock-displays">
                <div>name:"Dionysus Yummy" </div>
                <div>id:0382715</div>
                <div>snack: raspberries</div>
              </div>
              <div className="homepage_display-zeusdb_displayblock-displays">
                <div>name:"Berry Appleseed" </div>
                <div>id:7483279</div>
              </div>
            </div>
            {/* <div>
            <div>
              <div>platforms</div>
              <div>structure</div>
            </div>
          </div> */}
          </div>
          <div className="homepage_display-light">
            {/* <div>Light</div> */}
            <div className="homepage_display-light_objects-titlecontainer">
              <div className="inlines homepage_display-light_objects-title1">
                Light
              </div>
              <div className="inlines homepage_display-light_objects-title12">
                GordianFlowsInc
              </div>
              {/* <div className="homepage_display-light_objects-title12">
              databuildinc
            </div> */}
            </div>
            <div className="homepage_display-light_objects-panel">
              <div className="block homepage_display-light_objects-panel1">
                <div className="inlines homepage_display-light_objects-title2">
                  platform 1
                </div>
                <div className="inlines homepage_display-light_objects-title2">
                  platform 2
                </div>
              </div>
              <div className="inlines">
                <div className="block homepage_display-light_objects-title3">
                  struc 1
                </div>
                <div className="block homepage_display-light_objects-title3">
                  struc 2
                </div>
                <div className="block homepage_display-light_objects-title3">
                  data 3
                </div>
                <div className="block homepage_display-light_objects-title3">
                  data struc 4
                </div>
              </div>
              <div className="inlines">
                <div className="block homepage_display-light_objects-title3">
                  data
                </div>
                <div className="block homepage_display-light_objects-title3">
                  null
                </div>
                <div className="block homepage_display-light_objects-title3">
                  long
                </div>
                <div className="block homepage_display-light_objects-title3">
                  id
                </div>
              </div>
              <div className="inlines">
                <div className="block homepage_display-light_objects-title3">
                  new
                </div>
                <div className="block homepage_display-light_objects-title3">
                  num
                </div>
                <div className="block homepage_display-light_objects-title3">
                  str
                </div>
                <div className="block homepage_display-light_objects-title3">
                  struc
                </div>
              </div>
              <div className="inlines">
                <div className="block homepage_display-light_objects-title3">
                  24
                </div>
                <div className="block homepage_display-light_objects-title3">
                  db.new
                </div>
                <div className="block homepage_display-light_objects-title3">
                  print
                </div>
                <div className="block homepage_display-light_objects-title3">
                  array[]
                </div>
              </div>
              {/* <div className="inlines">
                <div className="block homepage_display-light_objects-title3">
                  struc 1
                </div>
                <div className="block homepage_display-light_objects-title3">
                  struc 2
                </div>
                <div className="block homepage_display-light_objects-title3">
                  data 3
                </div>
                <div className="block homepage_display-light_objects-title3">
                  data struc 4
                </div>
              </div> */}
            </div>
          </div>
          {/* <div>
            scroll
            <div>relax and go !</div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
